import React from 'react'
import ResponsiveContainer from '../responsive-container/responsive-container'
import vid from '../../assets/VideoCollection.mp4'

const Collection = () => {

    return (
        <>
            <ResponsiveContainer vid={vid} text={'Scopri la nostra collezione'} buttonPath={'/collezione'} containerName={'collection'} />
        </>
    )
}

export default Collection








