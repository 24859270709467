import React, { useState, useEffect } from 'react';
import ResponsiveTile from '../responsive-tile/responsive-tile';
import img from '../../assets/tre ragazze-senza sfondo.png';
import { Row, Col } from 'react-bootstrap';
import linksData from '../../utils/constants/linksData';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '../welcome/welcome.css';
import ResponsiveContainer from '../responsive-container/responsive-container';
import { Link } from 'react-router-dom';

const Welcome = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const buttonText = 'Prenota un appuntamento';
  const buttonPath = 'https://prenotazioni.salon-assistant.com/salon_booking/mvninoAOUG3jGY2iZF3da4DD154cH8Ld?fbclid=PAZXh0bgNhZW0CMTEAAaa9fQGn9boSIKStsQwcSzdUKSzTNdgheFiUDWfxeOnJd1lWp_3nYG29PlU_aem_ASkEGPvd9-33h9b8qXrfvxnIS3qg6LvfiOH9m53mL_-MZ1p08PMgxc0_A62nv81W6_yg8wlQwejJwHTZZZoZ3yhY';

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, windowHeight]);

  return (
    <>
      {windowHeight > windowWidth ? (
        <ResponsiveContainer containerName={'welcome'} img={img} text={buttonText} buttonPath={buttonPath} linksData={linksData} />
      ) : (
        <div className="welcome-container">
          <Row className="welcome-row">
            <Col sm={6} md={6} className="welcome-logo-col">
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} className='container-welcome-button'>
                <h2 style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '8vh' }} className='titolo-container-desktop'>BENVENUT* IN NARCISO</h2>
                <section className='container-bottoni-welcome-mobile'>
                  {linksData && linksData
                    .filter(link => link.hasOwnProperty('titleButtonWelcome') && link.titleButtonWelcome !== null)
                    .map((link, index) => (
                      <Link target='_blank' key={index} to={link.to}>
                        <p className='bottoni-container-welcome-desktop'>{link.titleButtonWelcome}</p>
                      </Link>
                    ))}
                </section>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} style={{ position: 'relative' }}>
              <ResponsiveTile img={img} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Welcome;




