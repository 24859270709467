import dolceSoleLogoWB from '../../assets/dolce-sole-logo.png';
import solariWB from '../../assets/solari-WB.png';
import prodigio from '../../assets/prodigio-wb.png';
import prodigioLogo from '../../assets/prodigio-logo.png';

const productGradient = {
    productLogo: dolceSoleLogoWB,
    productImg: solariWB
}
const productData = [
    {   
        productName: 'Prodigio',
        productLogo: prodigioLogo,
        productImg: prodigio
    }
]

export { productGradient, productData };



