import React from 'react';
import '../responsive-tile/responsive-tile.css'

const ResponsiveTile = ({ img }) => {

    return (
        <div className='div-responsive-tile'>
            <img className='div-img' alt='container-img' src={img} />
        </div>
    );
}

export default ResponsiveTile;






