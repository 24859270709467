import React from 'react';
import Button from '@mui/material-next/Button';
import { Link, useLocation } from 'react-router-dom';

const ButtonCustom = ({ text, link, setOpenModal }) => {

    const location = useLocation();

    const button = (
        <Button
            sx={{
                borderColor: '#fff',
                backgroundColor: 'rgba(25, 17, 11, .2)',
                backdropFilter: 'blur(15px)',
                color: '#fff',
                border: 1,
                fontSize: '1.25em',
                marginTop: location.pathname === '/store' ? '3vh' : '0vh'
            }}>
            {text}
        </Button>
    );

    return (
        <>
            {setOpenModal ? (
                <button onClick={() => setOpenModal(true)}>
                    {button}
                </button>
            ) : (
                <Link target='_blank' to={link}>
                    {button}
                </Link>
            )}
        </>
    );
};

export default ButtonCustom;
