import biondaTrasparente from '../../assets/Bionda-Video.mp4';
import mora from '../../assets/VideoMora.mp4';
import castana from '../../assets/Castana-Video.mp4';
import biondaIMG from '../../assets/bionda-14.jpeg';
import moraIMG from '../../assets/moraIMG.jpeg';
import castanaIMG from '../../assets/castanaIMG.jpeg';

const collectionData = [
    {
        vid: mora,
        img: moraIMG,
        id: 0,
        title: 'Brunette',
        font: 'Arial',
        color: 'Black'
    },
    {
        vid: castana,
        img: castanaIMG,
        id: 1,
        title: 'tofee',
        font: 'Arial',
        color: 'Brown'
    },
    {
        vid: biondaTrasparente,
        img: biondaIMG,
        id: 2,
        title: 'Barbie Chic',
        font: 'Barbie',
        color: 'Pink'
    },
    {
        vid: mora,
        img: moraIMG,
        id: 3,
        title: 'Brunette',
        font: 'Arial',
        color: 'Black'
    },
    {
        vid: castana,
        img: castanaIMG,
        id: 4,
        title: 'tofee',
        font: 'Arial',
        color: 'Brown'
    },
    {
        vid: biondaTrasparente,
        img: biondaIMG,
        id: 5,
        title: 'Barbie Chic',
        font: 'Barbie',
        color: 'Pink'
    }
]

export default collectionData




