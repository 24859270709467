import React, { useState } from 'react'
import './modal-menu-content.css'
import { Link } from 'react-router-dom';
import FollowUs from '../follow-us/follow-us';
import linksData from '../../utils/constants/linksData';
import CustomModal from '../custom-modal/custom-modal';
import SocialBar from '../social-bar/social-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { HiOutlineX } from "react-icons/hi";
import { Button } from 'react-bootstrap';
const ModalMenuContent = ({ closeAllModals }) => {

    const [openModaleContatti, setOpenModaleContatti] = useState(false)

    const contactModalContent =
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className='div4button' style={{ marginTop: '2vh', marginRight: '5vh', width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button style={{ display: 'contents' }} onClick={() => setOpenModaleContatti(false)}>
                        <HiOutlineX style={
                            { width: '1.5em', height: '1.5em', color: 'black' }
                        } />
                    </Button>
                </div>
                <p style={{ marginTop: '2vh', display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon style={{ marginRight: '1vh', fontSize: '1.5em' }} icon={faLocationDot} />
                    <a style={{ fontSize: '1.5em' }} target='blank' href="https://maps.app.goo.gl/tmrZgMQd7gN9CBkC7">Via Germania, 24, Figline e Incisa Valdarno</a>
                </p>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon style={{ marginRight: '1vh', fontSize: '1.5em' }} icon={faPhone} />
                    <a style={{ textDecoration: 'none', fontSize: '1.5em' }} href="tel:353 454 4984">353 454 4984</a>

                </p>
            </div>
            <SocialBar style={{ marginBottom: '0vh' }} ></SocialBar>
            <div style={{ height: '100%', width: '100%' }} className='mappa'>
                <iframe title="maps-position" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.987742813641!2d11.460344876927811!3d43.62761405408426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132bb1d62ffa255b%3A0x19ee8dd218e2b251!2sNarciso%20Parrucchieri!5e0!3m2!1sit!2sit!4v1718447973551!5m2!1sit!2sit" style={{ width: '400', height: '300', border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" marginTop='5vh' width='100%' height='100%'></iframe>
            </div>
        </>

    return (
        <>
            {!openModaleContatti &&
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5vh', fontWeight: '400', fontSize: '3.5em' }}>
                    {linksData.map((linkData, index) => (
                        linkData.text === 'CONTATTI' ?
                            <p onClick={() => setOpenModaleContatti(true)} className='hover'>{linkData.text}</p>
                            :
                            <Link onClick={() => closeAllModals()} key={index} style={{ marginBottom: linkData.marginBottom }} to={linkData.to}>
                                {linkData.text === 'PRENOTAZIONE' || 'STORE' ?
                                    <a target='_blank' rel="noreferrer" href={linkData.to} className='hover'>{linkData.text}</a>
                                    :
                                    <a href={linkData.to} className='hover'>{linkData.text}</a>
                                }
                            </Link>
                    ))}
                    <div style={{ marginTop: '5vh' }}>
                        <FollowUs backgroundColor={'transparent'} />
                    </div>
                </div>
            }
            <CustomModal showModal={openModaleContatti} setShowModal={setOpenModaleContatti} modalContent={contactModalContent} />
        </>
    )
}

export default ModalMenuContent
