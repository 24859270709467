import React, { useEffect, useState } from 'react';
import '../prodotti-component/prodotti-component.css';
import GradientNavbar from '../gradient-navbar/gradient-navbar';
import ProductTile from '../product-tile/product-tile';
import dolceSoleGradient from '../../utils/constants/gradients'
import { productGradient, productData } from '../../utils/constants/productData';

const ProdottiComponent = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, windowHeight]);

  return (
    <>
      <GradientNavbar
        windowWidth={windowWidth}
        logo={productGradient.productLogo}
        productImage={productGradient.productImg}
        gradientColor={dolceSoleGradient}
      />
      {productData && (
        <>
          {productData.map((product, index) => (
            <ProductTile
              logo={product.productLogo}
              product={product.productImg}
              windowWidth={windowWidth}
              index={index}
              name={product.productName}
            />
          ))}
        </>
      )}

    </>
  );
}

export default ProdottiComponent;