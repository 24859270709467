const linksData = [
    {
        text: 'STORE',
        to: 'https://prenotazioni.salon-assistant.com/salon_ecommerce/mvninoAOUG3jGY2iZF3da4DD154cH8Ld',
        marginBottom: '5vh',
        titleButtonWelcome: 'VISITA LO STORE'
    },
    {
        text: 'PRENOTAZIONE',
        to: 'https://prenotazioni.salon-assistant.com/salon_booking/mvninoAOUG3jGY2iZF3da4DD154cH8Ld?fbclid=PAZXh0bgNhZW0CMTEAAaa9fQGn9boSIKStsQwcSzdUKSzTNdgheFiUDWfxeOnJd1lWp_3nYG29PlU_aem_ASkEGPvd9-33h9b8qXrfvxnIS3qg6LvfiOH9m53mL_-MZ1p08PMgxc0_A62nv81W6_yg8wlQwejJwHTZZZoZ3yhY',
        marginBottom: '5vh',
        titleButtonWelcome: 'PRENOTA UN APPUNTAMENTO'
    },
    {
        text: 'PRODOTTI',
        to: '/store',
        marginBottom: '5vh'
    },
    {
        text: 'COLLEZIONE',
        to: '/collezione',
        marginBottom: '5vh'
    },
    {
        text: 'CONTATTI',
        to: '/contatti',
        marginBottom: '2vh'
    }
];

export default linksData;
