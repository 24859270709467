import React from 'react';
import logoBianco from '../../assets/logo-bianco.png';
import logoNero from '../../assets/logo-narciso.png';

const Logo = ({ colore }) => {
    return (
        <img
            src={colore === 'bianco' ? logoBianco : logoNero}
            style={{ width:'auto', height:'10vh', objectFit:'contain' }}
            className="img-fluid"
            alt={colore === 'bianco' ? 'Logo Bianco' : 'Logo Nero'}
        />
    );
};

export default Logo;
