const dolceSoleGradient =
{
  "colors": [
    {
      "color": "#F56411",
      "enabled": true
    },
    {
      "color": "#DC7A1B",
      "enabled": true
    },
    {
      "color": "#FFC85E",
      "enabled": true
    },
    {
      "color": "#FFBC00",
      "enabled": true
    },
    {
      "color": "#E8C223",
      "enabled": false
    }
  ],
  "speed": 6,
  "horizontalPressure": 3,
  "verticalPressure": 3,
  "waveFrequencyX": 2,
  "waveFrequencyY": 4,
  "waveAmplitude": 8,
  "shadows": 0,
  "highlights": 2,
  "colorBrightness": 1,
  "colorSaturation": 3,
  "wireframe": false,
  "colorBlending": 5,
  "backgroundColor": "#003FFF",
  "backgroundAlpha": 1,
  "resolution": 1
};

export default dolceSoleGradient