import React from 'react';
import FollowUs from '../follow-us/follow-us';
import Logo from '../logo/logo'
import '../footer/footer.css'

export default function Footer() {
  
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '3vh' }}>
        <div style={window.innerWidth > window.innerHeight ? 
          { marginBottom: '5vh', display: 'flex', alignItems: 'center', flexDirection: 'column' } :
          { display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Logo colore={'nero'} />
          <p className='p-footer' style={{ marginTop: '3vh' }}>Via Germania, 24, 50063 Figline e Incisa Valdarno FI</p>
          <p className='p-footer' style={{textDecoration:'none'}} href="tel:353 454 4984">353 454 4984</p>
          </div>
        <div style={{ marginTop: '3vh', marginBottom: '5vh' }}>
        <FollowUs backgroundColor={'white'} />
        </div>
      </div>
    </>

  );
}


