import  Navbar  from './components/navbar/navbar';
import './App.css';
import CollectionComponent from './components/collection-component/collection-component.js';
import Home from './components/home/home.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProdottiComponent from './components/prodotti-component/prodotti-component.js';
import Footer from './components/footer/footer.js';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';

function App() {

  return (
    <>
      <Router>
      <ScrollToTop />
    <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store" element={<ProdottiComponent/>} />
          <Route path="/collezione" element={<CollectionComponent />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;

