import React from 'react'
import ResponsiveContainer from '../responsive-container/responsive-container'
import img from '../../assets/prodotti-3.jpeg'

const Product = () => {
  return (
    <ResponsiveContainer style={{ marginTop: '0%' }} img={img} text={'Scopri i nostri prodotti'} buttonPath={'/store'}></ResponsiveContainer>
  )
}

export default Product