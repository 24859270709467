import { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Logo from '../logo/logo';
import { Button, Row, Col } from 'react-bootstrap';
import './navbar.css'
import ModalMenuContent from '../modal-menu-content/modal-menu-content';
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { HiOutlineX } from "react-icons/hi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import ModalCartContent from '../modal-cart-content/modal-cart-content';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
    const values = [true];
    const location = useLocation()
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [showCarrello, setShowCarrello] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
        setShowCarrello(false)
    }

    const closeAllModals = useCallback(() => {
        setShowCarrello(false);
        setShow(false)
    }, [setShowCarrello, setShow])

    return (
        <>
            <div style={
                location.pathname === '/store' && show === false ?
                    { backgroundColor: 'inherit', position: 'absolute', overflow: 'hidden' } :
                    { backgroundColor: '#252525' }
            }
                className='navbar-container'>
                <Row className="justify-content-center">
                    <Col xs={3} sm={3} md={3} className="d-flex justify-content-center align-items-center">
                        {values.map((v, idx) => (
                            show === false ? (
                                <Button
                                    style={
                                        location.pathname === '/store' ?
                                            {
                                                color: 'white',
                                                backgroundColor: 'inherit',
                                                border: 'none'
                                            } :
                                            {
                                                color: 'white',
                                                backgroundColor: '#252525',
                                                border: 'none'
                                            }

                                    }
                                    key={idx}
                                    className="me-2 mb-2"
                                    onClick={() => handleShow(v)}
                                >
                                    <HiOutlineMenuAlt4 style={

                                        { width: '1.7em', height: '1.7em', color: 'white' }
                                    } />
                                    {typeof v === 'string' && `sotto ${v.split('-')[0]}`}
                                </Button>
                            ) : (
                                <Button style={{ display: 'contents' }} onClick={() => setShow(false)}>
                                    <HiOutlineX style={
                                        { width: '1.7em', height: '1.7em', color: 'white' }
                                    } />
                                </Button>
                            )
                        ))}
                    </Col>
                    <Col xs={6} sm={6} md={6} className="d-flex justify-content-center align-items-center">
                        <Link onClick={() => closeAllModals()} to="/" >
                            <Logo colore={'bianco'} />
                        </Link>
                    </Col>
                    <Col xs={3} sm={3} md={3} className="d-flex justify-content-center align-items-center">

                        <Link target='_blank' to={'https://prenotazioni.salon-assistant.com/salon_ecommerce/mvninoAOUG3jGY2iZF3da4DD154cH8Ld'}>
                            <HiOutlineShoppingBag style={
                                { width: '1.7em', height: '1.7em', color: 'white' }
                            }
                            />
                        </Link>
                    </Col>
                </Row>
            </div>
            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} style={{ marginTop: '15vh' }}>
                <Modal.Body>
                    <ModalMenuContent closeAllModals={closeAllModals} />
                </Modal.Body>
            </Modal>
            <Modal show={showCarrello} fullscreen={fullscreen} onHide={() => setShowCarrello(false)} style={{ marginTop: '15vh' }}>
                <Modal.Body>
                    <ModalCartContent />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Navbar;