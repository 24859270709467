import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Col from 'react-bootstrap/Col';
import '../swiper-component/swiper-component.css';

const SwiperComponent = ({ collection }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectedSlide = (swiper) => {
    setSelectedIndex(swiper.realIndex)
  }

  return (
    <Col style={window.innerWidth > window.innerHeight ? { height: '100%' } : { height: '' }} className='swiper-container' xs={12} sm={12} md={window.innerWidth > 767 && window.innerWidth < window.innerHeight ? 12 : 12}>
      <Swiper
        slidesPerView={'auto'}
        style={{height: '100%'}}
        spaceBetween={10}
        onSlideChange={selectedSlide}
        loop={true}
        centeredSlides={true}
      >
        {collection.map((image, index) => (
          <div className='item' key={image.id}>
            <SwiperSlide key={image.id}>
              {selectedIndex === index ? (
                <video autoPlay muted loop style={window.innerWidth < window.innerHeight ? {objectFit: 'cover', height: '70vh', width: '100%'} : { objectFit: 'cover', height: '100%', width: '100%' }} src={image.vid} alt={`Slide ${image.id}`} />
              ) : (
                <img style={window.innerWidth > window.innerHeight ? 
                  { objectFit: 'none', height: '100%', width: '100%' } : 
                  { objectFit: 'cover', height: '70vh', width: '100%' }}
                  src={image.img} alt={`Slide ${image.id}`}
                  />
              )}
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </Col>
  );
};

export default SwiperComponent;

