import React, { useState } from 'react'
import ButtonCustom from '../button-custom/button-custom';
import '../product-tile/product-tile.css'
import linksData from '../../utils/constants/linksData';
import CustomModal from '../custom-modal/custom-modal'

const ProductTile = ({ logo, product, index, windowWidth, name }) => {

    const [openModalDetail, setOpenModalDetail] = useState(false)

    return (
        <>
            <div className='product'>
                <img className='product-logo' src={logo} alt={`logo ${name}`} />
                <section className='button-section'>
                    <ButtonCustom text='Ordina subito' link={linksData[0].to} />
                    {/* <ButtonCustom text='Scopri di più' setOpenModal={setOpenModalDetail} /> */}
                </section>
                <img className='product-img' src={product} alt={`product ${name}`} />
            </div>
            <CustomModal showModal={openModalDetail} setShowModal={setOpenModalDetail} modalContent={'ciao'} />
        </>
    )

}

export default ProductTile