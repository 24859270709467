import React from 'react';
import './follow-us.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';

const FollowUs = (backgroundColor) => {

    return (
        <>
            <div style={{display:'flex', gap:'3vh'}} class="icons">
                <a style={{background:{backgroundColor}}} href="https://www.facebook.com/Narcisoparrucchierifigline/" target="_blank" rel="noreferrer" class="fb"><i><FontAwesomeIcon icon={faFacebookF} /></i></a>
                <a style={{background:{backgroundColor}}} href="https://www.instagram.com/narciso.parrucchieri/" target="_blank" rel="noreferrer" class="insta"><i class="fab fa-instagram"><FontAwesomeIcon icon={faInstagram} /></i></a>
                <a style={{background:{backgroundColor}}} href="https://www.tiktok.com/@narciso.parrucchieri/" class="tiktok" target="_blank" rel="noreferrer"><i class="fab fa-tiktok"><FontAwesomeIcon icon={faTiktok} /> </i></a>
            </div>
        </>
    );
}

export default FollowUs;






