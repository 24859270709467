import React, { useState, useEffect } from 'react';
import SwiperComponent from '../swiper-component/swiper-component';
import '../collection-component/collection-component.css'
import collection from '../../utils/constants/collectionData'

const CollectionComponent = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, windowHeight]);

  return (
    <>
      <div className='collection-component-container'>
        <div className='swiper-thumbnail' style={window.innerWidth > window.innerHeight ?
          { display: 'flex', flexDirection: 'row', height: '80vh', alignItems: 'flex-end' } :
          { display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
          <SwiperComponent collection={collection} />
        </div>

      </div>
    </>
  );
}

export default CollectionComponent;




