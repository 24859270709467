import React from 'react'
import Navbar from '../navbar/navbar'
import Welcome from '../welcome/welcome'
import Product from '../product/product'
import Collection from '../collection/collection'

const Home = () => {
    return (
        <>
            <Navbar />
            <div className='containerHome' style={{
                display: 'flex', flexWrap: 'wrap', backgroundColor: 'white', width: '100%', paddingTop: (window.innerWidth < 768 ? '1%' : '1%'), paddingRight: (window.innerWidth < 768 ? '0%' : '0.25%'), paddingLeft: (window.innerWidth < 768 ? '0%' : '0.25%')
            }}>
                <Welcome />
                <Product />
                <Collection />
            </div>
        </>
    )
}

export default Home



