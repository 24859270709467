import React, { useEffect, useRef } from 'react'
import ButtonCustom from '../button-custom/button-custom';
import { NeatGradient } from "@firecms/neat";
import '../gradient-navbar/gradient-navbar.css'
import linksData from '../../utils/constants/linksData';
import CustomModal from '../custom-modal/custom-modal'
import { useState } from 'react';
import prodigioBlu from '../../assets/prodigio-blu.png'

const GradientNavbar = ({ windowWidth, logo, productImage, gradientColor }) => {

  const canvasRef = useRef(null);
  const [openModalDetail, setOpenModalDetail] = useState(false)

  const modalContent =
    <>
      <h2>Contenuto Kit</h2>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: '2vh' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h5>ELISIR PRODIGIO</h5>
          <img alt='prodigio-blu' style={{ width: '50%' }} src={prodigioBlu}></img>
        </div>
        <div style={{height:'100%'}}>
        <p style={{width:'100%'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc efficitur auctor massa at mollis. Praesent ut rhoncus tellus. Nunc elementum sem nec ornare rhoncus. Vivamus vitae ante quis ligula cursus sodales ut vel lectus. Donec sed mauris pharetra, efficitur risus nec, convallis lectus. Suspendisse vitae enim ac nulla consectetur luctus. Suspendisse sed mauris pharetra, ullamcorper quam ornare, hendrerit erat.</p>

        </div>
      </div>
    </>


  useEffect(() => {
    const config = gradientColor
    if (canvasRef.current) {
      const neat = new NeatGradient({
        ref: canvasRef.current,
        ...config
      });
      return () => {
        if (neat)
          neat.destroy();
      }
    }
  }, [gradientColor]);

  return (
    <>
      <div className="container-gradient-nav">
        <canvas id="gradient" ref={canvasRef} />
        <div className='prodotto'>
          <img className="logo-img" src={logo} alt="dolceSoleLogoWB" />
          <section className='button-section'>
            <ButtonCustom text='Ordina subito' link={linksData[0].to} />
            {/* <ButtonCustom text='Scopri di più' setOpenModal={setOpenModalDetail} /> */}
          </section>
          <img className="product-img" src={productImage} alt="Solari" />
        </div>
      </div>
      <CustomModal showModal={openModalDetail} setShowModal={setOpenModalDetail} modalContent={modalContent} modalName={'productModal'} size={'xl'} />
    </>
  )
}

export default GradientNavbar
