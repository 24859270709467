import React, { useState, useEffect, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material-next/Button';
import { Link } from 'react-router-dom';
import './responsive-container.css'

function ResponsiveContainer({ img, vid, text, buttonPath, containerName, style, linksData }) {
    const [, setWindowWidth] = useState(window.innerWidth);
    const [currentStyle, setCurrentStyle] = useState({});

    const defaultStyle = useMemo(() => {
        return {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            height: '75vh'
        };
    }, []);

    const smallScreenStyle = useMemo(() => {
        return {
            paddingLeft: '2%',
            paddingRight: '2%',
            paddingBottom: '1.5%',
            paddingTop: '1%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: (containerName === 'collection' ? '' : '15vh'),
        };
    }, [containerName]);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWindowWidth(width);
            if (width < 768 || window.innerWidth < window.innerHeight) {
                setCurrentStyle({ ...defaultStyle, ...smallScreenStyle });
            } else {
                setCurrentStyle(defaultStyle);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [defaultStyle, smallScreenStyle]);

    return (
        <>
            <Col
                xs={12}
                sm={12}
                md={window.innerHeight > window.innerWidth ? 12 : 6}
                style={{
                    marginTop: ((containerName === 'collection' && window.innerWidth < 768) || (window.innerHeight > window.innerWidth && containerName === 'collection')) ? '' : '',
                    padding: '0.25%'
                }}
            >
                <div style={{ ...currentStyle, ...style }}>
                    {containerName === 'welcome' &&
                        <>
                            <h2 className='titolo-container-mobile'>BENVENUT* IN NARCISO</h2>
                            <section className='container-bottoni-welcome-mobile'>
                                {linksData && linksData
                                    .filter(link => link.hasOwnProperty('titleButtonWelcome') && link.titleButtonWelcome !== null)
                                    .map((link, index) => (
                                        <Link target='_blank' key={index} to={link.to}>
                                            <p className='bottoni-container-welcome-mobile'>{link.titleButtonWelcome}</p>
                                        </Link>
                                    ))}
                            </section>
                        </>
                    }
                    {img ? (
                        <img
                            className='img-video'
                            style={containerName === 'welcome' && window.innerHeight > window.innerWidth ? {
                                width: '100%',
                                height: '75%',
                                objectFit: 'cover',
                                overflow: 'hidden'
                            } : {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                overflow: 'hidden'
                            }}
                            src={img}
                            alt="container-foto"
                        />
                    ) : (
                        <video
                            className='img-video'
                            autoPlay
                            muted
                            loop
                            src={vid}>
                        </video>
                    )}
                    {containerName !== 'welcome' &&
                        <Link
                            className="custom-link-style"
                            to={buttonPath}
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                sx={{
                                    borderColor: '#fff',
                                    backgroundColor: 'rgba(25, 17, 11, .2)',
                                    backdropFilter: 'blur(15px)',
                                    color: '#fff',
                                    border: 1,
                                    fontSize: '1.25em'
                                }}
                            >
                                {text}
                            </Button>
                        </Link>
                    }
                </div>
            </Col>
        </>
    );
};

export default ResponsiveContainer;